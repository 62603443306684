<template>
  <div class="alert alert-danger mb-5"
       role="alert">
    <p v-if="error.message">{{ error.message }}</p>
    <p v-else>Une erreur est survenue, merci de réessayer...</p>
    <ul v-if="hasErrorsField">
      <li v-for="(item, name) in error.errors"
          :key="name">
        <strong>{{ fieldName(name) }}</strong> : {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      fields: {
        city_end: 'Ville d\'arrivée',
        city_start: 'Ville de départ',
        comment: 'Commentaire',
        date_end: 'Date de fin de validité',
        date_start: 'Date de début de validité',
        height: 'Dimensions (h) (en m)',
        id_city_end: 'Ville d\'arrivée',
        id_city_start: 'Ville de départ',
        kilogram: 'Poids',
        length: 'Longueur (en m)',
        id: 'Id',
        num: 'Numéro interne',
        num_authorization_on_pdf: 'Numéro de l\'autorisation',
        pdf_path: 'Document de l\'autorisation',
        renewal_asked: 'Demande de renouvellement',
        renewal_date_ask: 'Demande de renouvellement effectuée le',
        renewal_date_last_status: 'Demande de renouvellement effectuée le',
        renewal_num: 'N° de demande',
        renewal_status: 'Renouvellement accordé',
        status: 'Statut',
        tonnage: 'Tonnage',
        tonne: 'Poids',
        width: 'Largeur (en m)',
        name: 'Nom du tronçon',
        page_number: 'Page PDF'
      }
    }
  },
  computed: {
    hasErrorsField () {
      return this.error.errors !== undefined
        && Object.keys(this.error.errors).length >= 1
    }
  },
  methods: {
    fieldName (name) {
      return Object.prototype.hasOwnProperty.call(this.fields, name) ? this.fields[name] : name
    }
  }
}
</script>
