<template>
  <div class="form-group">
    <label v-if="label"
           :for="id">
      {{ label }}
    </label>
      <Datepicker @selected="updateValue"
                  @cleared="clear"
                  :value="value"
                  :placeholder="placeholder"
                  :language="fr"
                  :use-utc="true"
                  :monday-first="true"
                  :bootstrap-styling="true"
                  :clear-button="true"
                  clear-button-icon="icon-close"
                  :calendar-button="false"
                  format="dd/MM/yyyy"
                  ref="datePicker">
        <span slot="afterDateInput"
              class="calendar-icon">
          <i @click="openCalendar"
             class="icon-date-picker"></i>
        </span>
      </Datepicker>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { fr } from 'vuejs-datepicker/dist/locale'

export default {
  components: {
    Datepicker
  },
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: false,
      default: 'JJ/MM/AAAA'
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: false,
      default: null
    }
  },
  data () {
    return {
      fr
    }
  },
  methods: {
    openCalendar () {
      // todo see https://github.com/charliekassel/vuejs-datepicker/issues/792
      // does not close unless the icon is clicked again
      this.$refs.datePicker.showCalendar()
    },
    updateValue (date) {
      if (date === null) {
        this.$emit('input', null)
      } else {
        this.$emit('input', Date.parse(date))
      }
    },
    clear () {
      this.updateValue(null)
    }
  }
}
</script>

<style lang="scss">
  .calendar-icon {
    border-bottom: 1px solid $red;
    color: $gray-text;
    padding-left: 5px;
    padding-right: 5px;
    width: 25px;

    i {
      vertical-align: -10px;
      font-size: 18px;

      @media (max-width: $breakpoint-md) {
        font-size: 16px;
      }
    }
  }

  .vdp-datepicker__clear-button {
    border-bottom: 1px solid $red;
    padding-right: 10px;
    background: none;

    @media (max-width: $breakpoint-md) {
      padding-right: 5px;
    }

    i {
      font-size: 13px;
      color: $red;

      @media (max-width: $breakpoint-md) {
        font-size: 11px;
      }
    }

    .input-group-text {
      background: none;
    }
  }

  .vdp-datepicker {
    @media (max-width: $breakpoint-md) {
      font-size: 13px;
    }

    .form-control:disabled,
    .form-control[readonly] {
      background: none;
    }

    .vdp-datepicker__calendar {
      width: 100% !important;
      color: $red !important;
      z-index: 1000;

      header .prev:after {
        border-right-color: $red !important;
      }

      header .next:after {
        border-left-color: $red !important;
      }

      .cell:hover,
      .day:hover,
      .month__year_btn:hover {
        border-color: transparent !important;
        background: rgba($red, .1);
      }

      .cell.selected {
        background: $red !important;
        color: $white;
      }
    }

    .vdp-datepicker__calendar header .prev:not(.disabled):hover,
    .vdp-datepicker__calendar header .next:not(.disabled):hover,
    .vdp-datepicker__calendar header .up:not(.disabled):hover {
      border-color: transparent !important;
      background: rgba($red, .1) !important;
    }
  }

  .vdp-datepicker__calendar {
    .cell {
      @media (max-width: $breakpoint-md) {
        height: 33px !important;
        line-height: 33px !important;
      }
    }
  }
</style>
