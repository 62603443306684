<template>
  <div :class="formGroupClasses">
    <label v-if="label"
           :for="id">
      {{ label }}
    </label>
    <div class="input-group">
      <select v-model="selected"
              @change="updateValue($event.target)"
              :id="id"
              :name="name"
              :disabled="disabled"
              class="form-control">
        <option v-if="placeholder && !selectedAtStartup"
                :value="null"
                disabled
                selected>
          {{ placeholder }}
        </option>
        <option v-for="(option, index) in options"
                :key="index"
                :value="option[valueToSelect]"
                :selected="option.selected">
          {{ option[labelField] }}
        </option>
      </select>
      <div class="input-group-append">
        <div class="inner">
          <i class="icon-next"></i>
        </div>
      </div>
    </div>
    <div v-if="error"
         class="help-block">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    valueToSelect: {
      type: String,
      required: true
    },
    labelField: {
      type: String,
      required: false,
      default: 'name'
    },
    selectedAtStartup: {
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    error: {
      type: String,
      required: false,
      default: null
    },
    showIconDownMini: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      selected: this.selectedAtStartup || null
    }
  },
  computed: {
    formGroupClasses () {
      return {
        'form-group': true,
        'has-error': !!this.error,
        'has-icon-down-mini': this.showIconDownMini
      }
    }
  },
  methods: {
    updateValue (target) {
      this.$emit('input', target.options[target.selectedIndex].value)
    }
  }
}
</script>

<style scoped lang="scss">
  .inner i {
    transform: rotate(90deg);
    display: inline-block;
  }

  .input-group-append {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    pointer-events: none;
  }

  .has-icon-down-mini {
    position: relative;

    &:after {
      font-family: icomoon;
      content: "\e90a";
      display: block;
      position: absolute;
      top: 11px;
      right: -6px;
      font-size: 8px;
      color: $red;
      pointer-events: none;
    }

    .form-control {
      font-size: 12px;
      border-bottom: none;
      margin-right: 5px;
    }

    .input-group-append {
      display: none;
    }
  }
</style>
